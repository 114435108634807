<template>
    <!--<div class="main-content-area">-->
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar>
                <v-btn icon="mdi-arrow-left" size="x-large" variant="text" @click="backButton"></v-btn>
            </v-toolbar>
            <v-toolbar>
                <v-toolbar-title>
                    <h2>Gate {{ gateType }}</h2>
                </v-toolbar-title>
            </v-toolbar>
            <v-toolbar>
                <v-btn v-if="!showFilter" icon="mdi-delete" size="x-large" variant="text" @click="deleteTask"></v-btn>
            </v-toolbar>
        </div>
        <div class="center-flex" v-if="!ready">
            <DataLoading :type=2 />
        </div>
        <div v-if="ready" class="main-content-playground" style="padding:20px; display: flex;flex-direction: column;">
            <div style="display: flex;flex-direction: column;flex-grow:1;">
                <div style="display: flex; flex-grow: 1;">
                    <v-row class="text-left align-center">
                        <v-col cols="3">
                            <div v-if="data.task.type === 'return'">
                                <v-icon class="text-h1" color="green">mdi-truck-delivery</v-icon>
                            </div>
                            <div v-else>
                                <v-icon class="text-h1" color="red"
                                    style="transform: scaleX(-1)">mdi-truck-delivery</v-icon>
                            </div>
                        </v-col>
                        <v-col cols="3">
                            <div class="text-h3 text-bold task-data">{{ data.task.data }}</div>
                            <div class="text-h5 text-bold task-carrier">{{ taskCarrier }}</div>
                        </v-col>
                        <v-col cols="3">
                            <div class="text-h3 text-bold task-operator">{{ taskOperator }}</div>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end">
                            <div class="text-h3 text-bold task-iso">{{ taskIsoCode }}</div>
                        </v-col>
                    </v-row>
                </div>
                <div class="flex-grow">
                    <v-row class="info-row" no-gutters>
                        <v-col cols="6" no-gutters>
                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">Reference:</v-col>
                                <v-col class="value-col">{{ taskReference }}</v-col>
                            </v-row>
                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">Container Condition:</v-col>
                                <v-col class="value-col">{{ taskContainerCondition }}</v-col>
                            </v-row>
                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">Freight Kind:</v-col>
                                <v-col class="value-col">{{ utilities.capitalizeFirstLetter(taskFreightKind) }}</v-col>
                            </v-row>
                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">Cargo Condition:</v-col>
                                <v-col class="value-col">{{ taskCargoCondition }}</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" v-if="data.task.type === 'return'" no-gutters>
                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">Cleaning:</v-col>
                                <v-col class="value-col">{{ reportCleaning }}</v-col>
                            </v-row>

                            <v-row class="info-row" no-gutters>
                                <v-col class="text-bold label-col">M&R Status:</v-col>
                                <v-col class="value-col">{{ reportMrStatus }}</v-col>
                            </v-row>
                            <v-row class="info-row" v-if="isReefer" no-gutters>
                                <v-col class="text-bold label-col">PTI Status:</v-col>
                                <v-col class="value-col">
                                    <v-icon v-if="reportPtiStatus" class="pti-icon"
                                        :color="reportPtiColor">mdi-circle</v-icon>
                                    {{ reportPtiStatus }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div style="padding-bottom:10px; display: flex;flex-direction: column;">
                <v-row v-if="data.task.type === 'release' || referenceComment || containerComment || taskComment">
                    <v-col cols="12">
                        <v-card class="combined-card" elevation="2">
                            <v-card-text>
                                <v-row class="text-left align-center" no-gutters>
                                    <v-col v-if="data.task.type === 'release' && isReefer" cols="2" lg="1">
                                        <v-icon v-if="containerTemperature || containerVentilation || containerHumidity"
                                            class="text-h1" color="warning">
                                            mdi-alert
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" lg="11">
                                        <v-row v-if="data.task.type === 'release' && isReefer">
                                            <v-col cols="4" lg="4">
                                                <v-icon class="text-h3" color="primary">mdi-thermometer</v-icon>
                                                <span class="text-h6 text-bold">Temperature: </span>
                                                <span class="text-h6">{{ containerTemperature }}</span>
                                            </v-col>
                                            <v-col cols="4" lg="4">
                                                <v-icon class="text-h3" color="primary">mdi-fan</v-icon>
                                                <span class="text-h6 text-bold">Ventilation: </span>
                                                <span class="text-h6">{{ containerVentilation }}</span>
                                            </v-col>
                                            <v-col cols="4" lg="4">
                                                <v-icon class="text-h3" color="primary">mdi-water-percent</v-icon>
                                                <span class="text-h6 text-bold">Humidity: </span>
                                                <span class="text-h6">{{ containerHumidity }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider
                                            v-if="data.task.type === 'release' && isReefer && (referenceComment || containerComment)"
                                            class="my-3"></v-divider>
                                        <v-row v-if="referenceComment" align="center" no-gutters class="mt-2">
                                            <v-col cols="1">
                                                <v-icon class="comment-icon" color="primary">
                                                    mdi-comment
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="11">
                                                <span class="comment-label">Reference Comment:</span>
                                                {{ referenceComment }}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="taskComment" align="center" no-gutters class="mt-2">
                                            <v-col cols="1">
                                                <v-icon class="comment-icon" color="primary">
                                                    mdi-comment
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="11">
                                                <span class="comment-label">Task Comment:</span>
                                                {{ taskComment }}
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="containerComment" align="center" no-gutters class="mt-2">
                                            <v-col cols="1">
                                                <v-icon class="comment-icon" color="primary">
                                                    mdi-comment
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="11">
                                                <span class="comment-label">Container Comment:</span>
                                                {{ containerComment }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div v-if="data.task.status" style="display: flex;flex-direction: column;flex-grow:1;">
                <div style='display: flex; flex-grow: 1;padding-bottom:20px;'>
                    <v-row cols="12" sm="12">
                        <v-col class="rowBtnCol center" v-if="data.task.task__stock_container == null">
                            <v-btn :disabled="data.task.status !== 'in_progress'" color="primary" size="x-large"
                                width="100%" height="100%" class="white--text" @click="selectContainerButton">
                                <span class="text-h3" style="font-weight: bold;text-align: left;">
                                    {{ selectedStockContainer }}
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col v-else>
                            <v-btn :disabled="data.task.gateInOutType == 'numerical' || data.task.type == 'return'"
                                color="primary" size="x-large" width="100%" height="100%" class="white--text"
                                @click="selectContainerButton">
                                <span class="text-h3" style="font-weight: bold;text-align: left;">
                                    {{ data.task.task__stock_container.unit_number }}
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col class="rowBtnCol center" v-if="data.task.type == 'return'" cols="3">
                            <v-btn
                                :disabled="data.task.status !== 'in_progress' || !data.task.task__stock_container == null || containerPositions.length == 1"
                                color="primary" size="x-large" width="100%" height="100%" class="white--text"
                                v-on:click="showFilter = true;">
                                <span v-if="containerPosition" class="text-h3" style="font-weight: bold;">
                                    <v-icon color="green">
                                        mdi-map-marker
                                    </v-icon>
                                    {{ containerPosition }}
                                </span>
                                <span v-else class="text-h3" style="font-weight: bold;">
                                    <v-icon class="text-h3">
                                        mdi-map-marker
                                    </v-icon>
                                    Position
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div style="display: flex; flex-grow: 1;">
                    <v-row cols="2" sm="2" buttom v-show="data.task.status === 'in_progress'">
                        <v-col class="rowBtnCol center">
                            <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                                @click="cancelTask">
                                <v-icon class="text-h3">
                                    mdi-stop-circle
                                </v-icon>
                                <span class="text-h3" style="font-weight: bold;">
                                    Cancel
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col class="rowBtnCol">
                            <v-btn v-if="data.task.type == 'release'" disabled color="primary" size="x-large" width="100%"
                                height="100%" class="white--text" @click="loader = 'loading3'">
                                <v-icon class="text-h3">
                                    mdi-diameter
                                </v-icon>
                                <span class="text-h3" style="font-weight: bold;">
                                    Restow
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col class="rowBtnCol">
                            <v-btn v-if="data.task.type == 'release'" disabled color="primary" size="x-large" width="100%"
                                height="100%" class="white--text" @click="loader = 'loading3'">
                                <v-icon class="text-h3">
                                    mdi-close-circle
                                </v-icon>
                                <span class="text-h3" style="font-weight: bold;">
                                    Rejected
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col class="rowBtnCol center position-relative">
                            <v-btn
                                :disabled="(!position && data.task.type == 'return') || !data.task.task__stock_container || containerBlocked"
                                color="primary" size="x-large" width="100%" height="100%" class="white--text"
                                v-on:click="data.task.status = 'completed'">
                                <v-icon v-if="containerBlocked" class="text-h3">
                                    mdi-lock-outline
                                </v-icon>
                                <v-icon v-else class="text-h3">
                                    mdi-check-circle
                                </v-icon>

                                <span class="text-h3" style="font-weight: bold;">
                                    Done
                                </span>
                            </v-btn>
                            <div v-if="(!position && data.task.type == 'return') || !data.task.task__stock_container || containerBlocked"
                                style="position: absolute; top: 0; right: 0; bottom: 0; left: 0;" @click="handleDoneClick">
                            </div>

                        </v-col>
                    </v-row>
                    <v-row cols="12" sm="12" buttom v-show="data.task.status === 'open'">
                        <v-col class="rowBtnCol center" cols="12">
                            <v-btn color="primary" size="x-large" width="100%" height="100%"
                                v-on:click="data.task.status = 'in_progress'">
                                <v-icon class="text-h2">
                                    mdi-play-circle
                                </v-icon>
                                <span class="text-h2" style="font-weight: bold;">
                                    Start
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div v-show="showFilter == true"
                style="position: absolute;top:0;left:0;width:100%;height:100%;border-right:1px solid #babfc7">
                <KeypadInput type="list-left" :backdrop=false connect="parent" :show="showFilter" :all="'content'"
                    :allowed="containerPositions" :key_layout="'normal'" :done_button=true :input_selection=true
                    :autocomplete=true :reset_on_open=true @update="filterUpdate" @done="toggleFilter" />
            </div>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications :height=40 :font=16 />
            <v-toolbar :height="40">
                <v-btn icon="mdi-circle" size="small" color="light-green darken-1" variant="text"></v-btn>
                <v-btn size="x-small" variant="text">
                    <h2>Status: online</h2>
                </v-btn>
            </v-toolbar>
        </div>
    </div>
</template>

<script setup>


//IMPORTS
import { reactive, inject, ref, defineProps, computed, onBeforeMount, onActivated, getCurrentInstance, watch } from 'vue';
import YamaxStock from '@/components/yamax/YamaxStock'
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useTransporter } from '@/composables/useTransporter';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { useUtilities } from '@/composables/useUtilities';
import { useNotification } from '@/composables/useNotification';
import KeypadInput from '@/components/ui/KeypadInput';



//INJECTIONS
const axios = inject('axios');
let data = reactive({ task: {} });



//VARIABLES
let position = ref('');



//CONSTANTS
const store = useAppStore();
const componentId = getCurrentInstance().uid;
const router = useRouter();
const components = {
    YamaxStock
}
const selectedStockContainer = ref(null);
const depotPositions = ref([]);
const ready = ref(false);
const showFilter = ref(false);



//COMPOSABLES
const { transporter } = useTransporter();
const { advancedAxios } = useAdvancedAxios(axios);
const { notification } = useNotification();
const { utilities } = useUtilities();



//PROPS
const props = defineProps({
    id: {
        type: String,
        required: true,
        default: null,
        hover: false,
    }
})



//COMPUTED

//reference comment
const referenceComment = computed(() => {
    if (data.task.task__reference) {
        return data.task.task__reference.comment;
    }
    if (data.task.task__stock_container) {
        return data.task.task__stock_container.reference_comment;
    }
    return '';
})

//container comment
const containerComment = computed(() => {
    return data.task.task__stock_container ? data.task.task__stock_container.comment : '';
})

const containerPositions = computed(() => {
    return data.task.task__stock_container ? data.task.task__stock_container.stock_container__positions.map(item => item.position) : [];
})

const taskStatus = computed(() => {
    return data.task.status;
})

const containerBlocked = computed(() => {
    let blocked_status = '1';
    if (data.task.task__stock_container) {
        blocked_status = data.task.task__stock_container.blocked;
    }

    if (blocked_status == '1') {
        return true;
    } else {
        return false;
    }
})

const taskOperator = computed(() => {
    if (data.task.type === "release") {
        return data.task.task__stock_container && data.task.task__stock_container.shipping_line__company ? data.task.task__stock_container.shipping_line__company.name : '';
    } else {
        return data.task.task__stock_container && data.task.task__stock_container.shipping_line__company ? data.task.task__stock_container.shipping_line__company.name : '';
    }
})

const gateType = computed(() => {
    if (data.task.type === "release") {
        return 'OUT';
    } else {
        return 'IN';
    }
})

const taskIsoCode = computed(() => {
    if (data.task.type === "release") {
        return data.task.task__reference ? data.task.task__reference.survey_size_type_id : '';
    } else {
        return data.task.task__stock_container ? data.task.task__stock_container.iso_code : '';
    }
})

const isReefer = computed(() => {
    let isoCode = '';
    if (data.task.type === "release") {
        isoCode = data.task.task__reference ? data.task.task__reference.survey_size_type_id : '';
        //search in isoCode if it contains 'R'
        if (isoCode.includes('R')) {
            return true;
        } else {
            return false;
        }
    } else {
        isoCode = data.task.task__stock_container ? data.task.task__stock_container.survey_size_type__survey_size_type.name : '';
        //search the survey_size_type if its a reefer
        if (isoCode.includes('Reefer')) {
            return true;
        } else {
            return false;
        }
    }
})

const taskReference = computed(() => {
    return data.task.task__reference ? data.task.task__reference.reference : '-';
})

const taskContainerCondition = computed(() => {
    let container_condition = '';

    if (data.task.task__stock_container) {

        container_condition =
            data.task.task__stock_container.report__report
                ? data.task.task__stock_container.report__report.damaged : '';

        if (container_condition == '0') {
            container_condition = 'Intact';
        } else if (container_condition == '1') {
            container_condition = 'Damaged';
        } else if (container_condition == '2') {
            container_condition = 'Instant Repair';
        }
        else {
            container_condition = 'Unknown';
        }
    }
    return container_condition;
})

const taskFreightKind = computed(() => {
    if (data.task.type === "release") {
        return data.task.task__reference ? data.task.task__reference.freight_kind : '';
    } else {
        if (data.task.task__stock_container) {
            return data.task.task__stock_container.report__report ? data.task.task__stock_container.report__report.freight_kind : '';
        } else {
            return '';
        }
    }
})

const taskCargoCondition = computed(() => {

    let cargoCondition = '';

    if (data.task.type === "release") {
        cargoCondition = data.task.task__reference ? data.task.task__reference.cargo_condition_id : '';
    } else {
        cargoCondition = data.task.task__stock_container?.report__report?.cargo_condition;
    }

    switch (cargoCondition) {
        case 'GC':
            return 'General Cargo';
        case 'CW':
            return 'Damaged';
        case 'FG':
            return 'Food Grade';
        case 'PG':
            return 'Paper Grade';
        case 'FB':
            return 'Flexi Bag';
        case 'INS':
            return 'Insurance';
        case 'REB':
            return 'RedBull';
        case 'AUT':
            return 'Automobile Standard';
        case 'WW':
            return 'Wind & Water Tight';
        default:
            return 'Unknown';
    }
})

const reportCleaning = computed(() => {
    if (data.task.task__stock_container) {
        if (data.task.task__stock_container.report__report) {
            if (data.task.task__stock_container.report__report.estimate_item__estimate_item) {
                let cleaning = [];
                data.task.task__stock_container.report__report.estimate_item__estimate_item.forEach(item => {
                    if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'SC') {
                        cleaning.push('Steam Clean');
                    }
                    if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'AC') {
                        cleaning.push('Blowout');
                    }
                    if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'MC') {
                        cleaning.push('Special Clean');
                    }
                });
                //make the elements from cleaning array unique
                cleaning = [...new Set(cleaning)];
                //make a string from the array with commas between the elements
                cleaning = cleaning.join(', ');

                return cleaning;
            } else {
                return 'Unknown';
            }
        } else {
            return 'Unknown';
        }
    } else {
        return '';
    }
})

const reportPtiStatus = computed(() => {
    if (data.task.task__stock_container) {
        if (data.task.task__stock_container.report__report) {
            return data.task.task__stock_container.report__report.machinery_info__machinery_info ?
                data.task.task__stock_container.report__report.machinery_info__machinery_info.pti_description : 'Unknown';
        } else {
            return 'Unknown';
        }
    } else {
        return '';
    }
})

const reportPtiColor = computed(() => {
    if (data.task.task__stock_container) {
        if (data.task.task__stock_container.report__report) {
            if (data.task.task__stock_container.report__report.machinery_info__machinery_info) {
                if (data.task.task__stock_container.report__report.machinery_info__machinery_info.pti_description === 'Only Visual Inspection') {
                    return 'green';
                } else if (data.task.task__stock_container.report__report.machinery_info__machinery_info.pti_description === '*PTI/SPTI') {
                    return 'red';
                } else if (data.task.task__stock_container.report__report.machinery_info__machinery_info.pti_description === 'Short PTI') {
                    return 'red';
                } else if (data.task.task__stock_container.report__report.machinery_info__machinery_info.pti_description === 'Release to booking') {
                    return 'blue';
                } else {
                    return 'orange';
                }
            }
            return 'orange';
        } else {
            return 'orange';
        }
    } else {
        return 'orange';
    }
})

const reportMrStatus = computed(() => {
    if (data.task.task__stock_container) {
        if (data.task.task__stock_container.report__report) {
            if (data.task.task__stock_container.report__report.service_type == 25 &&
                data.task.task__stock_container.report__report.completed == 1) {
                return 'Box Inspection';
            }
            if (data.task.task__stock_container.report__report.service_type == 25 &&
                data.task.task__stock_container.report__report.completed == 2) {
                return 'PTI Bridge';
            }
        }
        return 'Unknown';
    } else {
        return '';
    }
})

const taskCarrier = computed(() => {
    if (data.task.type === "release" || data.task.type === "return") {
        return data.task.task__carrier ? data.task.task__carrier.name : '';
    } else {
        return 'INTERNAL';
    }
})

const taskComment = computed(() => {
    if (data.task.type === "release" || data.task.type === "return") {
        return data.task.comment ? data.task.comment : '';
    } else {
        return '';
    }
})

const containerPosition = computed(() => {
    return position.value;
    /*if (data.task.task__stock_container) {
        return data.task.task__stock_container.position ? data.task.task__stock_container.position : '';
    } else {
        return '';
    }*/
})

const containerTemperature = computed(() => {
    if (data.task.type === "release") {
        if (data.task.task__reference.release_stock_container__stock_container) {
            return data.task.task__reference.release_stock_container__stock_container.temperature ? data.task.task__reference.release_stock_container__stock_container.temperature : '';
        }
    }
    if (data.task.task__stock_container) {
        return data.task.task__stock_container.temperature ? data.task.task__stock_container.temperature : '';
    } else {
        return '';
    }
})

const containerHumidity = computed(() => {
    if (data.task.type === "release") {
        if (data.task.task__reference.release_stock_container__stock_container) {
            return data.task.task__reference.release_stock_container__stock_container.humidity ? data.task.task__reference.release_stock_container__stock_container.humidity : '';
        }
    }
    if (data.task.task__stock_container) {
        return data.task.task__stock_container.humidity ? data.task.task__stock_container.humidity : '';
    } else {
        return '';
    }
})

const containerVentilation = computed(() => {
    if (data.task.type === "release") {
        if (data.task.task__reference.release_stock_container__stock_container) {
            return data.task.task__reference.release_stock_container__stock_container.ventilation ? data.task.task__reference.release_stock_container__stock_container.ventilation : '';
        }
    }
    if (data.task.task__stock_container) {
        return data.task.task__stock_container.ventilation ? data.task.task__stock_container.ventilation : '';
    } else {
        return '';
    }
})



//EVENTS
onActivated(() => {
    ready.value = false;
    let transportData = transporter.getData(componentId);
    selectedStockContainer.value = transportData ? transportData.unit_number : 'Select Container';
    refreshTask();
})



//WATCHES
watch([taskStatus], ([newDataTask], [prevDataTask]) => {
    let onUpdate = () => {
        if (newDataTask.status === 'completed') {
            router.push({ name: 'RsoApp' });
        }
    }
    updateTask(onUpdate);
})



//METHODS


const handleDoneClick = () => {
    if (!position.value && data.task.type == 'return') {
        store.addNotificationMessage('Please select a position.', 'error');
        return;
    }
    if (!data.task.task__stock_container) {
        store.addNotificationMessage('Please select a container.', 'error');
        return;
    }
    if (containerBlocked.value) {
        store.addNotificationMessage('The container is blocked.', 'error');
        return;
    }
}

const updateTask = (onUpdate) => {
    let sendData = {
        "id": props.id,
        "status": data.task.status,
        "position": position.value,
        "temperature": containerTemperature.value,
        "humidity": containerHumidity.value,
        "ventilation": containerVentilation.value,
        "reference_id": data.task.reference_id,
        "licence_plate": data.task.data,
        "carrier_id": data.task.carrier_id
    }

    advancedAxios.sendRequest(componentId + ((data.task && data.task.id) ? data.task.id : ''), { method: 'put', url: '/api/yamaxTasks/' + props.id, data: sendData })
        .then(function (response) {
            if (typeof onUpdate === 'function') {
                onUpdate();
            }
            if (data.task.status == 'completed') {
                notification.notify('complete', 2, 'Task Completed', () => {
                    //
                });
                router.push({ name: 'RsoApp' });
            }
        })
}

const cancelTask = () => {
    axios({
        method: "put",
        url: "/api/yamaxTasks/" + props.id + "/cancel",
    }).then(function (response) {
        router.push({ name: 'RsoApp' });
    }).catch(function () {

    });
}

const deleteTask = () => {
    if (window.confirm("Are you sure you want to delete this task?")) {
        axios({
            method: "put",
            url: "/api/yamaxTasks/" + props.id + "/delete",
        }).then(function (response) {
            router.push({ name: 'RsoApp' });
        }).catch(function (error) {
            console.log(error);
        });
    }
}


const refreshTask = () => {
    axios.get('/api/yamaxTasks/' + props.id).then(function (response) {
        data.task = response.data.task;
        let newValue = response.data.task.task__stock_container ? response.data.task.task__stock_container.position : '';
        if (containerPositions.value.length == 1) {
            newValue = containerPositions.value[0];
        }
        position.value = newValue;
        ready.value = true;
    })
}

const backButton = (params) => {
    if (showFilter.value == true) {
        toggleFilter();
    } else {
        router.push({ name: 'RsoApp' });
    }
};

const selectContainerButton = (params) => {
    router.push({ name: 'YamaxStock', params: { task_id: data.task.id, transportId: componentId } });
};

const toggleFilter = () => {
    showFilter.value = !showFilter.value;
}

const filterUpdate = (data) => {
    if (containerPositions.value.length > 1) {
        position.value = data.value;
    }
}

</script>

<style scoped>
.task-data,
.task-carrier,
.task-operator,
.task-iso {
    color: rgb(53, 94, 127);
}

.task-carrier {
    color: grey;
}

.flex-grow {
    display: flex;
    flex-grow: 1;
}

.text-h5,
.text-bold {
    font-size: 2vw;
    font-weight: bold;
    margin-left: 0.5rem;
}

.label-col {
    min-width: 50%;
}

.value-col {
    text-align: left;
    font-size: 2vw;
}

.pti-icon {
    margin-right: 4px;
    font-size: 2vw;
}

.text-left {
    text-align: left;
}

.combined-card {
    background-color: #fffcfc;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.comment-icon {
    font-size: 1.2rem;
}

.info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.comment-label {
    font-weight: 600;
    margin-right: 4px;
    font-size: 0.9rem;
}
</style>