<template>
        <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-location-enter" size="small" variant="text" 
                       :disabled="allowAdd==false"
                       @click="addNewReturnReference"
                       v-tippy="{ content: 'Add a new Return reference' }"
                       help="1"></v-btn>
                <v-btn icon="mdi-location-exit" size="small" variant="text" 
                       :disabled="allowAdd==false"
                       @click="addNewReleaseReference"
                       v-tippy="{ content: 'Add a new Release reference' }"
                       ></v-btn>
                <v-btn icon="mdi-pencil" size="small" variant="text" 
                       :disabled="allowEdit==false"
                       @click="editReference"
                       v-tippy="{ content: 'Edit selected reference' }"
                       ></v-btn>
                <v-btn icon="mdi-delete" size="small" variant="text" 
                       :disabled="allowDelete==false"
                       @click="deleteReference"
                       v-tippy="{ content: 'Delete selected reference' }"
                       ></v-btn>
                <v-btn icon="mdi-reload" size="small" variant="text"
                       :disabled="allowAdd==false"
                       @click="common.reloadHandler(state, data, populateGridWithData)"
                       v-tippy="{ content: 'Reload reference list' }"
                       ></v-btn>
                <v-btn icon="mdi-check" size="small" variant="text" 
                       :disabled="state.isEditing=='no'" 
                       @click="finishEditingReference"
                       v-tippy="{ content: 'Finish editing reference' }"
                       ></v-btn>
                <v-btn icon="mdi-file-delimited-outline" size="small" variant="text" 
                    @click="exportCsv"
                    v-tippy="{ content: 'Export reference list as CSV file' }"
                        ></v-btn>
                <v-btn icon="mdi-history" size="small" variant="text" 
                    :disabled="allowEdit==false"
                    @click="showHistory"
                    v-tippy="{ content: 'Show change history of the reference' }"
                    ></v-btn>
                <v-btn :icon="filterRecords ? 'mdi-filter-remove-outline' : 'mdi-filter-check-outline' " size="small" variant="text" 
                    @click="toggleFilter"
                    v-tippy="{ content: filterRecords ?  'Show all references' : 'Show only active references' }"
                    ></v-btn>

            </v-toolbar>
            <v-toolbar :height="50">
                <v-btn icon="mdi-help-circle" size="small" variant="text" v-tippy="{ content: 'Activate help system. <br>[ Development in progress ]' }"></v-btn>
                <AppNotifications />
            </v-toolbar>
        </div>
        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue
                id="referenceGrid"
                ref="referenceGrid"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :components="components"
                :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData"
                :gridOptions="gridOptions"
                :editType="editType"
                @contextmenu="stopEditingOnRightClick"
                >
            </ag-grid-vue>
        </div>
            
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
                {{ filterRecords ? 'Active' : 'All' }} references: {{ totalRecords }}
            </v-toolbar>            
        </div>
            
        <DialogNeo dialog-title="Confirm" :show="state.dialog" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            Are you sure you want to delete the selected references?
                            <div v-for="(item, index) in state.nodesToDelete" :key="index" >
                                <!--{{ item.data.reference }}-->
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="right">
                            <v-btn color="primary" prepend-icon="mdi-cancel" @click="cancelDeleteReference">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Cancel
                            </v-btn>
                            <v-btn color="error" prepend-icon="mdi-delete" @click="confirmDeleteReference" style="margin-left: 15px;">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelDeleteReference"></v-btn>
            </template>
        </DialogNeo>

        <DialogNeo v-if="state.containersDialog===true" :show=true :dialog-header=false :dialog-footer=false dialog-width="90%" dialog-height="90%">
            <template  #content>
                <ContainerReferenceList 
                    :reference="state.editingReference" 
                    @close="closeContainersDialog" 
                    @data-update="dataUpdateContainers"
                    />
            </template>
        </DialogNeo>

        <DialogNeo v-if="history.dialog===true" :show=true :dialog-header=false :dialog-footer=false dialog-width="90%" dialog-height="90%">
            <template  #content>
                <HistoryList 
                    :history = "history"
                    @close="closeHistoryDialog" 
                    />
            </template>
        </DialogNeo>

    </div>
</template>

<script setup>



    //IMPORTS
    import jQuery from "jquery";
    import { inject, ref, reactive, computed, onActivated, onDeactivated, onMounted, onBeforeMount, nextTick, getCurrentInstance } from 'vue'
    import { useAppStore } from '@/stores/app'
    import { useReferenceDefinition } from '@/components/aggrid/definitions/useReferenceDefinition';
    import { useCommon } from '@/components/aggrid/common/useCommon';
    import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
    import ContainerReferenceList from '@/components/ContainerReferenceList';
    import HistoryList from '@/components/HistoryList';
    import { AgGridVue } from "ag-grid-vue3";
    import { useKeyboard } from '@/composables/useKeyboard';
    import _ from 'lodash';
            
    

    //CONSTANTS
    const componentId = getCurrentInstance().uid;
    const fields = {
        'reference': ['id', 'reference', 'type', 'freight_kind', 'survey_size_type_id', 'validity', 'depot_id', 'operator_id', 'leasing_company_id', 'quantity', 'inspection_criteria', 'cargo_condition_id', 'booking_category_id', 'transport_status_id', 'created_at', 'updated_at', 'comment','container_older','container_younger']
    };
    const components = {
        AgGridVue
    };

    

    //REFERENCES
    const referenceGrid = ref(null);



    //INJECTIONS
    const axios=inject('axios');
        const storage=inject('storageManager');
    const dayJs = inject('dayJs');
    
    

    //VARIABLES
    let gridApi = null;
    let gridApi2=ref(null);
    let columnApi = null;
    let columnApi2=ref(null);
    let deactivatingCellEditors = false;    
    
    
    //CONSTANTS
    const $ = jQuery;
    const store = useAppStore();
    const editType='fullRow';
    const data = reactive({
        rowData: null,
        pinnedTopRowData: [],
        pinnedBottomRowData: [],
    })
    const state = reactive({
        newReferences: 0,
        allowUnpin: false,
        isEditing: 'no',
        dialog: false,
        nodesToDelete: [],
        isAdding: false,    //While this is true one can't add another reference
        gridReady: false,
        containersDialog: false,
        editingReference: null,
        firstVisibleColumn: null
    })
    const filterRecords = ref(true);
    const totalRecords = ref('...');



    //COMPOSABLES
    const { keyboard } = useKeyboard();



    //COMPUTED
    const triggerButtonsUpdate=ref(true);
    const allowAdd = computed(() => {
        let t = triggerButtonsUpdate.value;
        return common.allowAdd(data, gridApi, state);
    });
    const allowEdit = computed(() => {
        let t = triggerButtonsUpdate.value;
        return common.allowEdit(data, gridApi, state);
    });
    const allowDelete = computed(() => {
        let t = triggerButtonsUpdate.value;
        return common.allowDelete(data, gridApi, state);
    });
    
    
    
    //COMPOSABLES
    const { common } = useCommon(referenceGrid, state, gridApi2, columnApi2, data);
    const { advancedAxios } = useAdvancedAxios(axios);
    const { getReferenceDefinition } = useReferenceDefinition(store, dayJs);
    //
    
    
    
    const columnDefs=ref([]);
    const history = reactive({
            dialog: false,
            model: 'Reference',
            record: null,
            columnDefs: columnDefs,
            prepareRecord: prepareRecord,
            columnDefsOverride: [
                {field: 'quantity',
                    tooltipComponentParams: null,
                    cellClass: null
                },
                {field: 'updated_at',
                    tooltipComponentParams: null,
                    cellClass: null
                }
            ],
            columnDefsDisables: {
                quantity: {}
            }
    });
    const defaultColDef=reactive({...common.defaultColDef, ...{
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: false
        }
    }});
    const gridOptions = /*reactive(*/{
        ...common.gridOptions,
        onFirstDataRendered: function(params) {
            //
        },
        
        noRowsOverlayComponentParams: {
            click: () => { gridApi.hideOverlay(); addNewReference(); }
        },
        
        onSortChanged: (e) => {
            //This method keeps the newly added records on top
            common.onSortChanged();
        },

        /*onFilterChanged:(e) =>{
            let filterModel = gridApi.getFilterModel(); 
            
            let filterModelNew={
                filter: '1',
                filterType: 'number',
                type: 'equal',
                colId: 'new'
            };

            const combinedFilter1 = {
                operator: 'AND',
                conditions: filterModel
            };
            const combinedFilter2 = {
                operator: 'OR',
                conditions: [combinedFilter1, filterModelNew]
            };

            filterModel['_customCombinedFilter'] = combinedFilter2;
            gridApi.setFilterModel(filterModel);
        },*/

        onRowDoubleClicked: (params) => {
                if (params.data.id==''){
                    store.addNotificationMessage('Reference: <strong>'+params.data.reference+'</strong> has to be saved first before adding !', 'error');
                }else{
                    if (state.isEditing=='no'){
                        state.editingReference = params.data;
                        state.containersDialog=true;
                    }
                }
        },
        onCellContextMenu: (params) => {
            common.onCellContextMenu(params, gridApi, state);
        },
        onGridReady: (params) => {
            gridApi = params.api;
            gridApi2.value = params.api;
            columnApi = params.columnApi;
            columnApi2.value = params.columnApi;
            common.onGridReady(params, getReferenceDefinition, columnDefs, state, populateGridWithData);
        },
        onRowDataChanged: () => {
            common.onRowDataChanged(columnApi);
            columnApi.autoSizeAllColumns();
        },
        onRowEditingStarted: (params) => {
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
            common.onRowEditingStarted(params, state, componentId);
        },
        onRowEditingStopped: (params) => {
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        },
        onRowClicked: (params) => {
            if (params.data.id==""){
                gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn });
            }
        },
        onCellValueChanged(params) {
            common.onCellValueChanged(params, columnApi);
        },
        onRowValueChanged: (params) => {
            if (params.data.id=='' || common.editedRowChanged(state, params.data)){
                let validation=validateRecord(params);
                if (validation.valid){
                    params.data.valid=true;
                        
                    let method='';
                    let url='/reference';
                    if (params.data.id==''){
                        method='post';
                    }else{
                        method='put';
                        url+='/'+params.data.id;
                    }
                    let sendData = {...params.data};
                    //We don't need to send this data
                    delete sendData.return_stock_containers__stock_container;
                    delete sendData.release_stock_containers__stock_container;
                    advancedAxios.sendRequest( componentId+(params.data.id ? params.data.id : params.data.uniqueId), {method: method, url: url, data: sendData})
                    .then(function (response) {
                        if (response.status===200){
                            //Update row with changes from backend
                            response.data.model.uniqueId = params.data.uniqueId;
                            response.data.model.valid = true;
                            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
                            store.addNotificationMessage('Reference: <strong>'+(params.data.reference!=''?params.data.reference:'')+'</strong> saved!', 'success');
                        }else{
                            params.data.valid=false;
                            params.node.setDataValue('valid', false);
                        }
                    }).catch(function (error) {
                        if (error.message!=='canceled'){
                            params.data.valid=false;
                            params.node.setDataValue("valid",false);
                        }
                    });
                }else{
                    params.data.valid=false;
                    params.node.setDataValue('valid', params.data.valid);
                    store.addNotificationMessage(validation.message, 'error');
                }
            }
            state.isEditing='no';
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        },
        
        onSelectionChanged: (params) => {
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        },
        onModelUpdated: (params) => {
            totalRecords.value = gridApi ? gridApi.getDisplayedRowCount() : '...';
        },
        onPinnedRowDataChanged: (params) => {
            state.allowUnpin=(data.pinnedTopRowData.length===0)?false:true;
        },
        onCellMouseDown: (params) =>{
            common.onCellMouseDown(params);
        },
        tabToNextCell(params) {
            return common.tabToNextCell(params, gridApi);
        },
        onBodyScroll: (event) => {
            if (!deactivatingCellEditors && event.direction=='horizontal'){
                deactivatingCellEditors=true;
                common.deactivateAllCellEditors(gridApi);
                setTimeout( function(){ deactivatingCellEditors=false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
            }
        },
        onBodyScrollEnd: (event) => {
            //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
            deactivatingCellEditors=false;
        },
        onCellClicked: (params) => {
            common.onCellClicked(params, store);
        }
    }
    
    
    
    //EVENTS
    onBeforeMount(() => {
    })
    onMounted(() => { })
    onActivated(() => {
        keyboard.addListener(componentId, onKeyPressHandler);
        keyboard.activate(componentId);
    })
    
    onDeactivated(() => {
        keyboard.removeListener(componentId);
    })
    
    
    
    //METHODS
    const onKeyPressHandler = (event) => {
        if (state.containersDialog===false){
            if (event.shiftKey){
                if (event.key=='+'){ addNewReturnReference(); }
                if (event.key=='-'){ addNewReleaseReference(); }
                if (event.key=='*'){ store.addNotificationMessage('Reference: has to be saved first before adding !', 'error'); }
            }else{
                common.onKeyPressHandler(event, gridApi, data, state);
            }
        }
    }
    
    const populateGridWithData = () => {
        state.gridReady = false;
        setTimeout(() => {
            gridApi.showLoadingOverlay();
        });
        if (storage.enabled()){
            storage.getData({
                id: 'reference',
                editedId: null,
                models: [
                    {url: 'reference/synchronize/reference', map: modelSubmodelMap.model, timer: 5000 },
                    {url: 'reference/synchronize/submodels', map: modelSubmodelMap.submodels, timer: 1000000 }
                ],
                onError: function(){ common.normalServerReload('/reference', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data ); },
                onUpdate: function(){ gridApi.setRowData(data.rowData); }
            });
        }else{
            common.normalServerReload('/reference/'+(filterRecords.value ? 'active' : 'all'), axios, state, gridApi, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onReloadSuccessError, onReloadSuccessError);
        }
    }

    const onReloadSuccessError = (response) => {
        state.gridReady=true;
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }
    
    const addNewReturnReference = () => {
        addNewReference('return');
    }
    const addNewReleaseReference = () => {
        addNewReference('release');
    }
    
    const addNewReference = (type) => {
        gridApi.setFilterModel(null);
        setTimeout(function(){
            gridApi.hideOverlay();
            state.isAdding=true;
            let newReference = prepareRecord({
                new: 1,
                type: type,
                operator_id: '',
                leasing_company_id: '',
                booking_category_id: ''
            });
            data.rowData.unshift(newReference);
            gridApi.setRowData(data.rowData);
            nextTick(() => {
                let node = gridApi.getRowNode(newReference.uniqueId); 
                if (node){
                    gridApi.startEditingCell({rowIndex: node.rowIndex, colKey:  state.firstVisibleColumn});
                    state.isEditing='existing';
                }
            })
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }, 1);
    }
    
    const editReference = () =>{
        gridApi.stopEditing();
        let rows=gridApi.getSelectedNodes();
        if (rows.length>0){
            let row=rows[0];
            gridApi.startEditingCell({rowIndex: row.rowIndex, colKey: state.firstVisibleColumn});
            state.isEditing='existing';
        }
    }

    const deleteReference = () =>{
        if (!common.deleteEditingRow(gridApi, data, state)){
            let nodes=gridApi.getSelectedNodes();
            state.nodesToDelete=[];
            nodes.forEach(item => state.nodesToDelete.push(item));
            state.dialog = true;
        }else{
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    }
    const confirmDeleteReference = () => {
        state.dialog=false
        let delData = [];
        let max=state.nodesToDelete.length;
        for (let i=0;i<max;i++){
            delData.push(state.nodesToDelete[i].data.id);
        }
        axios.delete('/reference/'+delData.join(',')).then(function (response) {
            if (response.status==200){
                let deleted = response.data.deleted.length ? response.data.deleted.split(",") : [];
                let max = deleted.length;
                for (let i = 0; i < max; i++) {
                    let delIndex = data.rowData.findIndex(o => o.id === parseInt(deleted[i]));
                    data.rowData.splice(delIndex, 1)
                    gridApi.setRowData(data.rowData)
                }
                state.nodesToDelete=[];
                triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
            }else{
                //
            }
        }).catch(function (error) { });
    }
    const cancelDeleteReference = () => {
        state.dialog=false
    }
    
    const cancelEditReference = () => {
        state.dialog=false
    }
    const finishEditingReference = (cancel=false) => {
        gridApi.stopEditing();
        state.isEditing='no';
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }
    
    const exportCsv = () => {
        common.exportCsv(gridApi, {
            processHeaderCallback: function (params) {
                switch (params.column.colId) {
                    case 'report':
                        return 'SC Flow Report';
                    default:
                        return params.column.colDef.headerName;
                }
            }
        }, {
            processCellCallback: function (cell) {
                if (cell.column.colId == 'receipts') {
                    if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date == '') {
                        return 'In';
                    }
                    if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date != '') {
                        return 'In/Out';
                    }
                    return false;
                } else {
                    return false;
                }
            }
        });
    }

    const toggleFilterToolbar = () => {
        defaultColDef.floatingFilter=!defaultColDef.floatingFilter;
    }
    
    const numberComparator = (a, b) => {
        return a-b;
    }    
    
    const closeContainersDialog = () => {
        state.containersDialog = false;
    }

    const closeHistoryDialog = () => {
        history.dialog = false;
    }
    
    const dataUpdateContainers = (updateData) => {
        let row = data.rowData.find(item => item.id==updateData.referenceId);
        if (!row){
            row = data.pinnedTopRowData.find(item => item.id==updateData.referenceId);
        }
        if (row){
            if (row.type=='return'){
                row.return_stock_containers__stock_container=updateData.containers;
            }else{
                row.release_stock_containers__stock_container=updateData.containers;
            }
            gridApi.setRowData(data.rowData);
        }
    }
    
    const stopEditingOnRightClick = () => {
        common.stopEditingOnRightClick(state, gridApi)
    }
    
    const showHistory = () => {
        let rows=gridApi.getSelectedNodes();
        if (rows.length>0){
            history.record = rows[0].data;
            history.dialog=true;
        }
    }

    const toggleFilter = () => {
        filterRecords.value = !filterRecords.value;
        common.reloadHandler(state, data, populateGridWithData);
    }

    function prepareRecord(item){
        return {
            ...common.prepareRecord(item)
            ,
            ...{
            valid: item.valid || true,
            actions: item.actions || '',
            id: item.id || '',
            type: item.type || 'return',
            reference: item.reference || '',
            created: item.created || '',
            validity: item.validity || '',
            quantity: item.quantity || '',
            survey_size_type_id: item.survey_size_type_id || null,
            operator_id: item.operator_id || item.operator_id===null ? item.operator_id : '',
            leasing_company_id: item.leasing_company_id || item.leasing_company_id===null ? item.leasing_company_id : '',
            depot_id: item.depot_id || null,
            freight_kind: item.freight_kind || null,
            inspection_criteria: item.inspection_criteria || null,
            cargo_condition_id: item.cargo_condition_id || null,
            transport_status_id: item.transport_status_id || null,
            booking_category_id: item.booking_category_id || null,
            container_older: item.container_older || null,
            container_younger: item.container_younger || null,
            updated_at: item.updated_at || '',
            created_at: item.created_at || '',
            deleted_at: item.deleted_at || null,
            comment: item.comment || '',
            condition: item.condition || null,
            created_by: _.get(item, 'created_by__user.name') || null,
            created_from: item.created_from || '',
            return_stock_containers__stock_container: item.return_stock_containers__stock_container || [],
            release_stock_containers__stock_container: item.release_stock_containers__stock_container || []
        } }
    }
    
    const validateRecord = (params) => {
        let valid=true;
        //To validate use: let valid = ReferenceListValidator.validate(params.data);
        if (valid!==true){
            return {
                valid: false,
                message: valid
            }
        }else{
            return {
                valid: true
            }
        }
    }
    
    const modelSubmodelMap ={
        model: {
            'reference': { prepareRecord: prepareRecord, target: ()=>{ if (data.rowData===null) { data.rowData=[]; } return data.rowData } }
        },
        submodels: {
            'survey_size_type_id': { id: 'code', name: ['code', 'survey_type__survey_type.name'], prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'survey_size_type_id').cellEditorParams.valueList } },
            'transport_status_id': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'transport_status_id').cellEditorParams.valueList } },
            'cargo_condition_id': { id: 'code',  prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'cargo_condition_id').cellEditorParams.valueList } },
            'booking_category_id': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'booking_category_id').cellEditorParams.valueList } },
            'operator_id': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'operator_id').cellEditorParams.valueList } },
            'depot_id': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field == 'depot_id').cellEditorParams.valueList } },
            'leasing_company_id': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'leasing_company_id').cellEditorParams.valueList } },
            'type': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'type').cellEditorParams.valueList } },
            'inspection_criteria': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'inspection_criteria').cellEditorParams.valueList } },
            'freight_kind': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'freight_kind').cellEditorParams.valueList } },
            'created_from': { prepareRecord: common.prepareListRecord, target: function(){ return columnDefs.value.find(def => def.field === 'created_from').cellEditorParams.valueList } }
        }
    };

</script>

<style lang="sass">
</style>

<script>
  export default {
    name: 'ReferenceList',
    customOptions: {}
  }
</script>